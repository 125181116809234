<template>
    <dv-full-screen-container v-if="isFullScreen" class="bigScreen">
        <slot></slot>
    </dv-full-screen-container>
    <div v-else class="bigScreen">
        <slot ></slot>
    </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: 'BigScreenContainer',
    computed: mapState({
        isFullScreen: state => {
            return state.app.isFullScreen;
        }
    })
};
</script>

<style lang="less" scoped>
    .bigScreen {
        width: 100%;
        height: 100%;
        overflow: hidden auto;
        background-image: url(~@/assets/images/widget/bigscreen-background.png);
        background-position: center;
        background-repeat: no-repeat;
    }

</style>
