/**
 * 首页小部件组件
 */

export default {
    // 用电行业情况分布
    DistributionElectricityIndustry: () => import('./component/DistributionElectricityIndustry.vue'),

    // 购售电收益
    PurchaseAndSaleOfElectricity: () => import('./component/PurchaseAndSaleOfElectricity'),

    // 损耗率统计
    AttritionRateStatistics: () => import('./component/AttritionRateStatistics'),

    // 用户用电量排名
    PowerConsumptionRanking: () => import('./component/PowerConsumptionRanking'),

    // 用电电压分布
    ElectricityVoltageDistribution: () => import('./component/ElectricityVoltageDistribution'),

    // 购售电收益
    SumOfPurchaseAndSaleOfElectricity: () => import('./component/SumOfPurchaseAndSaleOfElectricity'),

    // 电网状态
    PowerGridStatus: () => import('./component/PowerGridStatus'),

    // 配网地图
    PowerGridMap: () => import('./component/PowerGridMap'),

    // 重要指标
    ImportantIndicator: () => import('./component/ImportantIndicator'),

    // 组织架构
    Organization: () => import('./component/Organization/index'),

    // 用电用户缴费情况
    PaymentStatus: () => import('./component/PaymentStatus'),

    // 设备分布统计
    EquipmentDistribution: () => import('./component/EquipmentDistribution'),

    // 业扩流程指引
    BusinessApplyProcessGuide: () => import('./component/BusinessApplyProcessGuide'),

    // 业扩效率信息
    BusinessApplyEfficiency: () => import('./component/BusinessApplyEfficiency'),

    // 业务办理信息
    BusinessProcessInfo: () => import('./component/BusinessProcessInfo/index'),

    // 停电及故障电损统计
    PowerLossAndFailure: () => import('./component/PowerLossAndFailure'),

    // 安全隐患处理
    SecurityRisks: () => import('./component/SecurityRisks'),

    // 业务跟踪
    BusinessTracking: () => import('./component/BusinessTracking'),

    // 故障监控
    FaultMonitor: () => import('./component/FaultMonitor/index'),
};
