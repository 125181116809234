<template>
    <div class="headerContainer">
        <div class="left">
            <dv-decoration-8 class="decoration"/>
        </div>
        <div class="middle">
            <div class="ladder">
                <div class="inner">
                    <slot>
                        <div class="title">{{title}}</div>
                    </slot>
                </div>
            </div>
        </div>
        <div class="right">
            <dv-decoration-8 :reverse="true" class="decoration" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeHeader',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="less" scoped>

    @headerBorder: 2px solid #3f96a5;
    @height: 50%;
    @width: 35%;

    .headerContainer {
        font-size: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .left, .right {
            width: @width;
            height: 100%;

            .decoration {
                width: 100%;
                height: @height;
            }
        }
        .middle {
            width: calc(100% - @width * 2);
            height: 100%;
            display: inline-flex;

            .ladder {
                position:relative;
                width: 100%;
                height: calc(100% - @height /2);
                overflow:hidden;
                perspective: 200px;
                text-align: center;
                align-self: flex-end;

                .inner{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                    border-left: @headerBorder;
                    border-right: @headerBorder;
                    border-bottom: @headerBorder;
                    transform-origin:100% 0 0;
                    transform: rotateX(-30deg);
                    transform-style: preserve-3d;
                    box-sizing: border-box;
                    padding-bottom: 15px;

                    .title {
                        width: calc(100% - 20px);
                        font-weight: bold;
                        letter-spacing: 3px;
                        transform-origin:100% 0 0;
                        transform: rotateX(30deg);
                        font-size: 1.8vw;
                    }
                }
            }
        }
    }

    .full-screen {
        .header {
            .middle {
                .ladder {
                    margin-top: calc(5% - 2px);
                }
            }
        }
    }

</style>
