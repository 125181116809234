<template>
    <big-screen-container class="big-screen-container">
        <el-button type="primary" size="mini" icon="el-icon-d-arrow-left" @click="preClick"></el-button>
        <div class="main-home-index" ref="mainHomeIndex">
            <home-header class="header" :title="title"></home-header>
            <!-- <transition-group name="fade"> -->
                <div class="main-home-index__edit-popper" v-if="!isSmallWindow && statusGetLayoutsSuccess">
                    <el-button
                        size="mini"
                        v-if="!isEdit && ($authCheck('sys:widget:save') || $authCheck('sys:widget:update'))"
                        type="primary"
                        circle
                        icon="el-icon-edit-outline"
                        @click="handleActionEdit"
                        title="修改"
                    ></el-button>
                    <el-button
                        size="mini"
                        v-if="isEdit && ($authCheck('sys:widget:save') || $authCheck('sys:widget:update'))"
                        type="success"
                        circle
                        icon="el-icon-plus"
                        :disabled="loadingSaveLayouts"
                        @click="handleActionAdd"
                        title="新建"
                    ></el-button>
                    <el-button
                        size="mini"
                        v-if="isEdit && ($authCheck('sys:widget:save') || $authCheck('sys:widget:update'))"
                        type="cancel"
                        circle
                        icon="el-icon-close"
                        :disabled="loadingSaveLayouts"
                        @click="handleActionCancel"
                        title="取消"
                    ></el-button>
                    <el-button
                        size="mini"
                        v-if="isEdit && ($authCheck('sys:widget:save') || $authCheck('sys:widget:update'))"
                        type="warning"
                        circle
                        icon="el-icon-check"
                        :loading="loadingSaveLayouts"
                        @click="handleActionSave"
                        title="保存"
                    ></el-button>
                </div>
                <div
                    class="ui-layout-container ui-width-100 content"
                    v-if="statusGetLayoutsSuccess"
                    v-loading="loadingGetLayouts"
                >
                    <dv-border-box-1>
                        <div
                            class="main-home-index__layout ui-layout-content--full"
                            :class="[isEdit ? 'editing' : '', isSmallWindow ? 'wide' : '']"
                        >
                            <grid-layout
                                ref="gridLayout"
                                :layout.sync="layouts"
                                :col-num="layoutOptions.colNum"
                                :row-height="layoutOptions.rowHeight"
                                :is-draggable="isEdit && !isSmallWindow"
                                :is-resizable="isEdit && !isSmallWindow"
                                :is-mirrored="false"
                                :vertical-compact="true"
                                :margin="[layoutOptions.margin, layoutOptions.margin]"
                                :use-css-transforms="true"
                                class="main-home-index__layout-component"
                            >
                                <grid-item
                                    :ref="'grid_item_' + item.i"
                                    v-for="(item, index) in layouts"
                                    :key="item.i"
                                    :x="item.x"
                                    :y="item.y"
                                    :w="item.w"
                                    :h="item.h"
                                    :i="item.i"
                                    :min-h="2"
                                    :min-w="2"
                                    class="main-home-index__panel"
                                    :class="itemEditIndex === index ? 'hover' : ''"
                                >
                                    <!-- 正常状态 -->
                                    <div class="main-home-index__panel-body" v-if="!isEdit && index !== refreshIndex">
                                        <i
                                            class="el-icon-refresh ui-cursor--pointer"
                                            @click="refreshHandle(index)"
                                            title="刷新"
                                        ></i>

                                        <div
                                            class="
                                                ui-layout-flex
                                                ui-layout-flex--column
                                                ui-layout-flex--center
                                                ui-layout-flex--items_center
                                                ui-height-100
                                                ui-color--primary
                                                ui-padding-15
                                            "
                                            v-if="!$authCheck(item.authObj)"
                                        >
                                            <div class="ui-align--center">{{ isChinese ? item.name : item.eName }}</div>
                                            <div class="ui-align--center ui-font--small ui-color--danger">
                                                {{ $t('main.home-index.layout.widget.auth') }}
                                            </div>
                                        </div>
                                        <template v-else-if="hasComponent(item.component)">
                                            <component
                                                v-if="!(isEdit && newIds[item.i])"
                                                ref="a"
                                                :is="item.component"
                                                :base-widget-panel-title.sync="item.panelTitle"
                                                :base-widget-width="getItemPxWidth(item.w)"
                                            ></component>
                                        </template>
                                        <div
                                            class="
                                                ui-layout-flex
                                                ui-layout-flex--column
                                                ui-layout-flex--center
                                                ui-layout-flex--items_center
                                                ui-height-100
                                                ui-color--primary
                                                ui-padding-15
                                            "
                                            v-else
                                        >
                                            <div class="ui-align--center">{{ isChinese ? item.name : item.eName }}</div>
                                            <div class="ui-align--center ui-font--small ui-color--danger">无权限</div>
                                        </div>
                                    </div>
                                    <!-- 正常状态 -->

                                    <!-- 编辑状态 -->
                                    <div class="main-home-index__panel-editor" v-if="isEdit">
                                        <div class="main-home-index__panel-editor--header">
                                            <el-button
                                                class="main-home-index__panel-editor--edit"
                                                icon="el-icon-edit"
                                                type="text"
                                                title="编辑"
                                                @click="handleItemEdit(index)"
                                            ></el-button>
                                            <el-button
                                                class="main-home-index__panel-editor--close"
                                                icon="el-icon-close"
                                                type="text"
                                                title="关闭"
                                                @click="handleItemClose(index)"
                                            ></el-button>
                                        </div>
                                        <div
                                            class="
                                                main-home-index__panel-editor--body
                                                ui-color--primary
                                                ui-font--normal
                                            "
                                            :title="isChinese ? item.description : item.eDescription"
                                        >
                                            <div class="ui-align--center">{{ isChinese ? item.name : item.eName }}</div>
                                            <div
                                                v-if="!hasComponent(item.component)"
                                                class="ui-align--center ui-font--small"
                                            >
                                                无权限
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /编辑状态 -->
                                </grid-item>
                            </grid-layout>
                        </div>
                        <div class="main-home-index__edit-space" v-show="isEdit"></div>
                    </dv-border-box-1>
                </div>
                <div
                    class="ui-width-100 ui-layout-flex ui-layout-flex--center ui-layout-flex--items_center"
                    v-else
                >
                    <el-button size="medium" type="primary" plain icon="el-icon-refresh" @click="handleRetry">{{
                        '重试'
                    }}</el-button>
                </div>
            <!-- </transition-group> -->
            <el-dialog
                :title="'选择小部件'"
                width="600px"
                :visible.sync="showSelectWidgets"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                @close="handleDialogClose"
            >
                <el-table
                    stripe
                    :data="widgets"
                    class="ui-border"
                    row-class-name="ui-cursor--pointer"
                    @row-click="handleSelectWidgetsRowClick"
                    :max-height="500"
                >
                    <el-table-column :label="'小部件名称'" align="center">
                        <template slot-scope="scope">{{ isChinese ? scope.row.name : scope.row.eName }}</template>
                    </el-table-column>
                    <el-table-column :label="'小部件描述'" align="center">
                        <template slot-scope="scope">{{
                            isChinese ? scope.row.description : scope.row.eDescription
                        }}</template>
                    </el-table-column>
                </el-table>
            </el-dialog>
        </div>
        <el-button type="primary" size="mini" icon="el-icon-d-arrow-right" @click="nextClick"></el-button>
    </big-screen-container>
</template>
<script>
import VueGridLayout from 'vue-grid-layout';
import widgetsComponents from '@/components/widgets';
import HomeHeader from './HomeHeader';
import BigScreenContainer from '@/components/BigScreenContainer';
export default {
    name: 'BigScreen',
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        HomeHeader,
        BigScreenContainer,
        ...widgetsComponents(),
    },
    provide() {
        return {
            bigScreen: this
        };
    },
    data() {
        return {
            isEdit: false,
            layoutOptions: {
                colNum: 12,
                rowHeight: 100,
                margin: 20,
            },
            layouts: [],
            showSelectWidgets: false,
            widgets: [],
            layoutsTemp: null,
            itemEditIndex: -1,

            statusGetLayoutsSuccess: true,
            loadingGetLayouts: true,
            loadingSaveLayouts: false,

            // 编辑状态下添加或修改的组件
            newIds: {},

            // 小屏判断
            isSmallWindow: false,
            smallWindowWidth: 1100,

            // 单个比例的宽度
            itemWithMarginUnitWidth: 0,
            // 容器的宽度
            layoutWidth: 0,
            refreshIndex: -1,
            groupFlags: ['enterprise', 'business', 'powerGrid'],
            currentIndex: 0,
        };
    },
    methods: {
        preClick() {
            if (this.currentIndex === 0) {
                this.currentIndex = 2;
            } else {
                this.currentIndex--;
            }
        },
        nextClick() {
            if (this.currentIndex === 2) {
                this.currentIndex = 0;
            } else {
                this.currentIndex++;
            }
        },
        refreshHandle(index) {
            this.refreshIndex = index;
            this.$nextTick(() => {
                this.refreshIndex = -1;
            });
        },
        handleSelectWidgetsRowClick(row) {
            // 防止dialog正在关闭的动画过程中快速点击导致创建新的item
            if (this.showSelectWidgets === false) {
                return;
            }

            this.showSelectWidgets = false;

            if (this.itemEditIndex === -1) {
                this.triggerAdd({...row});
            } else {
                this.triggerEdit({...row});
            }

            this.itemEditIndex = -1;
        },
        handleActionAdd() {
            this.itemEditIndex = -1;
            this.showSelectWidgets = true;
        },
        handleActionEdit() {
            this.isEdit = true;
            this.layoutsTemp = this._.cloneDeep(this.layouts);
        },
        handleActionSave() {
            this.requestSaveLayouts();
        },
        handleActionCancel() {
            this.isEdit = false;
            this.layouts = this.layoutsTemp;
        },
        handleItemEdit(index) {
            this.itemEditIndex = index;
            this.showSelectWidgets = true;
        },
        handleFullScreen() {
            this.$store.dispatch('app/changeFullScreenStatus');
            const isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
            if (!isFullscreen) {
                //进入全屏,多重短路表达式
                const element = document.documentElement;
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen();
                }
            } else {
                //退出全屏,三目运算符
                // eslint-disable-next-line no-lonely-if
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            }
        },
        handleItemClose(index) {
            this.$delete(this.layouts, index);
        },
        handleDialogClose() {
            this.itemEditIndex = -1;
        },
        // 修改区域
        triggerEdit(widget) {
            const layout = this.layouts[this.itemEditIndex];

            if (layout.component !== widget.component) {
                this.newIds[layout.i] = true;
            }

            const isExists = this.layouts.some((o) => o.id === widget.id);

            if (!isExists) {
                this.$set(this.layouts, this.itemEditIndex, {
                    ...layout,
                    ...widget,
                });
            } else {
                this.$message.error(`${widget.name}小部件已存在`);
            }
        },
        // 添加区域
        triggerAdd(widget) {
            const id = this.getLayoutId();
            this.newIds[id] = true;

            const height = this.$refs.gridLayout.$el.clientHeight;
            const y = Math.ceil(height / this.layoutOptions.rowHeight);
            const item = {
                x: 0,
                y,
                w: 4,
                h: 2,
                i: id,
                panelTitle: '',
                ...widget,
            };
            const isExists = this.layouts.some((o) => o.id === item.id);

            if (!isExists) {
                this.layouts.push(item);
            } else {
                this.$message.error(`${widget.name}小部件已存在`);
            }

            setTimeout(() => {
                // const layoutTop = this.$refs.gridLayout.$el.getBoundingClientRect().top;
                const layoutTop = 0;
                const rowHeight = this.layoutOptions.rowHeight;
                const margin = this.layoutOptions.margin;
                const reserved = -80;
                const top = layoutTop + item.y * (rowHeight + margin) + reserved;
                this.$root.$emit('fe-framework:main-content-scroll', top);
            }, 0);
        },
        handleRetry() {
            this.requestInit();
        },
        // id生成
        getLayoutId() {
            return Date.now() + '_' + this._.random(0, 99999);
        },
        async requestSaveLayouts() {
            this.loadingSaveLayouts = true;

            try {
                const layoutsData = this.layouts.map((item) => {
                    // 发送给后端的数据去除 panelTitle
                    const itemData = {...item, panelTitle: undefined};

                    return itemData;
                });
                await this.$client.saveLayouts({
                    groupFlag: this.currentGroupFlag,
                    layouts: JSON.stringify(layoutsData),
                });

                this.$message({
                    message: '保存成功',
                    type: 'success',
                });

                this.isEdit = false;
            } catch (error) {
                this.$message({
                    message: '保存失败',
                    type: 'error',
                });
            }

            this.loadingSaveLayouts = false;
        },
        async requestInit() {
            this.loadingGetLayouts = true;
            try {
                const getWidgets = this.$client.getWidgets();
                const getLayouts = this.$client.getLayouts(this.currentGroupFlag);
                const {data: widgetsData} = (await getWidgets) || {data: []};
                const {data: layoutsData} = (await getLayouts) || {data: []};
                this.widgets = widgetsData;
                this.layouts = layoutsData.map((item) => {
                    return {...item, panelTitle: ''};
                });
                this.statusGetLayoutsSuccess = true;
            } catch (error) {
                this.statusGetLayoutsSuccess = false;
                this.$message({
                    message: '请求失败',
                    type: 'error',
                });
                throw error;
            }
            this.loadingGetLayouts = false;
        },
        hasComponent(component) {
            return this.widgets.some((o) => o.component === component);
        },
        windowResize() {
            if (this.$refs.mainHomeIndex.clientWidth <= this.smallWindowWidth) {
                this.isSmallWindow = true;
            } else {
                this.isSmallWindow = false;
            }

            this.layoutWidth = this.$refs.gridLayout.$el.clientWidth;
            this.itemWithMarginUnitWidth = (this.layoutWidth - this.layoutOptions.margin) / this.layoutOptions.colNum;
        },
        getItemPxWidth(itemColNum) {
            // 小屏每个部件都是占满
            if (this.isSmallWindow) {
                return this.layoutWidth - this.layoutOptions.margin * 2 - 2;
            }

            return this.itemWithMarginUnitWidth * itemColNum - this.layoutOptions.margin - 2;
        },
    },
    computed: {
        isChinese() {
            if (this.$store.state.i18nValue === 'zh-CN') {
                return true;
            }
            return false;
        },
        currentGroupFlag() {
            return this.groupFlags[this.currentIndex];
        },
        title() {
            switch (this.currentGroupFlag) {
                case 'enterprise':
                    return '企业画像';
                case 'business':
                    return '市场画像';
                case 'powerGrid':
                    return '配网监控';
                default:
                    return '';
            }
        },
    },
    watch: {
        isEdit: {
            immediate: true,
            handler(val) {
                if (!val) {
                    this.newIds = {};
                }
            },
        },
        currentGroupFlag() {
            this.requestInit();
        },
    },
    activated() {
        this.$nextTick(() => {
            //解决小部件在窗口缩放时，偶尔无法重绘页面的问题
            if (document.createEvent) {
                const event = document.createEvent('HTMLEvents');
                event.initEvent('resize', true, true);
                window.dispatchEvent(event);
            } else if (document.createEventObject) {
                window.fireEvent('onresize');
            }
        });
        this.windowResize();
        this.$root.$on('fe-framework:window-resize', this.windowResize);
    },
    deactivated() {
        this.$root.$off('fe-framework:window-resize', this.windowResize);
    },
    created() {
        this.requestInit();
    },
    mounted() {
        // 避免布局组件动画影响，延迟再次进行计算
        const transitionDuration = 220;
        setTimeout(() => {
            this.windowResize();
        }, transitionDuration);
    },
    beforeDestroy() {
        this.$root.$off('fe-framework:window-resize', this.windowResize);
    },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/common.less';


.big-screen-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@headerHeight: 80px;

/deep/ .ui-text-underline {
    padding: 0 0 8px 0;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.main-home-index {
    // width: 100%;
    flex: 1;
    height: 100%;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    color: #ffffff;

    &__edit-popper {
        position: fixed;
        right: 20px;
        bottom: 30px;
        z-index: 200;
    }

    &__edit-space {
        height: 40vh;
        width: 100%;
    }

    &__layout {
        width: 100%;
        position: relative;

        &:not(.editing) {
            .main-home-index__panel {
                transition-duration: 0s;
            }
        }

        &.editing {
            .main-home-index__panel {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border: 1px solid @color-parts-alpha;

                &:hover,
                &.hover {
                    box-shadow: 0 0 10px 0 @color-primary;
                }
            }

            /deep/ .vue-grid-item:not(.vue-grid-placeholder) {
                background: lighten(@color-primary, 52%);
                border: 2px dashed @color-primary;
            }

            /deep/ .vue-grid-item.vue-grid-placeholder {
                background-color: @color-primary;
            }

            /deep/ .vue-grid-item.vue-draggable-dragging {
                opacity: 0.8;
            }

            /deep/ .vue-grid-item.resizing {
                opacity: 0.7;
            }

            /deep/ .vue-grid-item > .vue-resizable-handle {
                // @svg-url: %('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"><path d="M7 7H0V5.2h5.2V0H7v7z" fill="%s" opacity="1"/></svg>', @color-primary);
                @svg-url: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik03IDdIMFY1LjJoNS4yVjBIN3Y3eiIgZmlsbD0iIzNkNTY4YyIgb3BhY2l0eT0iMSIvPjwvc3ZnPg==';
                background-image: url(@svg-url);
                background-origin: content-box;
                background-position: 100% 100%;
                background-repeat: no-repeat;
                z-index: 100;
            }
        }

        // 小屏下部件宽度布满
        &.wide {
            .main-home-index__layout-component {
                height: auto !important;
                padding: 20px;

                .main-home-index__panel {
                    margin-top: 20px;
                    position: relative !important;
                    transform: none !important;
                    width: 100% !important;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .header {
        height: @headerHeight;
    }

    .content {
        flex-grow: 1;
    }
}
/deep/.main-home-index__panel {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    touch-action: none;
    border-radius: 10px;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
    overflow: hidden;

    &-header {
        background-color: @color-parts-alpha;
        color: #fff;
        display: flex;

        &--title {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 36px;
            height: 36px;
            font-size: 14px;
            padding: 0 10px;
        }
    }

    &-body {
        flex: 1;
        overflow: auto;
        box-sizing: border-box;
    }

    &-editor {
        user-select: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &--header {
            position: absolute;
            top: 0;
            right: 10px;
            z-index: 100;

            /deep/ i {
                font-weight: bold;
            }
        }
    }
}
.el-icon-refresh {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 1000;
}
</style>
