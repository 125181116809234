<template>
    <div v-loading="true" class="ui-height-100 ui-width-100"></div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>

</style>
